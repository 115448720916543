import { ReviewIdEnum } from '@app/core';
import { NotificationInterface } from 'outshared-lib';
import { ProductGradeKeyValue } from './product-grade-key-value.interface';
import * as productGradeActions from './product-grade.actions';

export interface State {
    entities: ProductGradeKeyValue[];
    loading: ReviewIdEnum[];
    errors: NotificationInterface[];
}

export const initialState: State = {
    entities: [],
    loading: [],
    errors: [],
};

export function reducer(state: State = initialState, action: productGradeActions.ProductGradeActions): State {

    switch (action.type) {

        case productGradeActions.LOAD_PRODUCT_GRADE: {
            const isAlreadyLoading = state.loading.some((id: ReviewIdEnum) =>
                id === action.payload.reviewId,
            );

            if (isAlreadyLoading) {
                return {...state};
            }

            const newStateLoading = state.loading.slice();
            newStateLoading.push(action.payload.reviewId);
            return {
                ...state,
                loading: newStateLoading,
                errors: [],
            };
        }

        case productGradeActions.LOAD_PRODUCT_GRADE_SUCCESS: {
            const newStateLoading = state.loading.filter((key: ReviewIdEnum) =>
                key !== action.payload.productGradeKeyValue.key,
            );

            const newStateEntities = state.entities.slice();
            newStateEntities.push(action.payload.productGradeKeyValue);
            return {
                ...state,
                entities: newStateEntities,
                loading: newStateLoading,
                errors: [],
            };
        }

        case productGradeActions.LOAD_PRODUCT_GRADE_ERROR: {
            return {
                ...state,
                errors: action.payload.errors,
            };
        }

        case productGradeActions.RESET: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

/* selectors */
export const getEntities = (state: State) => state.entities;
export const getErrors = (state: State) => state.errors;
