import * as favoriteReviewsActions from '@app/store/actions/favorite_reviews.actions';
import { Review, Message } from '@app/core/interfaces';
import { createSelector } from '@ngrx/store';

export interface State {
    entities: { [product_name: string]: Review[] };
    selectedPageNumber: number;
    totalReviewPages: number;
    selectedId: string | null;
    errors: Message[];
    need: { [product_name: string]: boolean };
}

const initialState: State = {
    entities: {},
    selectedPageNumber: 0,
    totalReviewPages: 0,
    selectedId: null,
    errors: [],
    need: {}
};

export function reducer(state: State = initialState, action: favoriteReviewsActions.Actions): State {
    switch (action.type) {

        case favoriteReviewsActions.LOAD_FAVORITE_REVIEWS: {
            return {
                ...state,
            };
        }

        case favoriteReviewsActions.LOAD_FAVORITE_REVIEWS_SUCCESS: {
            if (!action.payload) {
                return state;
            }
            const product_name = state.selectedId;
            const reviews = action.payload.reviews;

            return {
                ...state,
                entities: Object.assign({}, state.entities, {
                    [product_name]: reviews
                }),
                selectedId: product_name,
                need: Object.assign({}, state.need, {
                    [product_name]: false
                }),
            };
        }

        case favoriteReviewsActions.SET_SELECTED_FAVORITE: {
            const product_name = action.payload.product_id;

            return {
                ...state,
                selectedId: product_name,
            };
        }

        case favoriteReviewsActions.NEED_SELECTED_FAVORITE: {
            const productName = action.payload.product_id;

            return {
                ...state,
                need: {
                    ...state.need,
                    [productName]: true
                }
            };
        }

        case favoriteReviewsActions.SET_ERROR: {
            return {
                ...state,
                errors: action.payload.errors
            };
        }

        case favoriteReviewsActions.SET_SELECTED_FAVORITE_REVIEW_PAGE_NUMBER: {
            return {
                ...state,
                selectedPageNumber: action.payload.pageNumber
            };
        }

        case favoriteReviewsActions.SET_TOTAL_FAVORITE_REVIEW_PAGES: {
            return {
                ...state,
                totalReviewPages: action.payload.totalReviewPages
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
export const getErrors = (state: State) => state.errors;
export const getSelectedPageNumber = (state: State) => state.selectedPageNumber;
export const getSelectedId = (state: State) => state.selectedId;
export const getTotalReviewPages = (state: State) => state.totalReviewPages;
export const getSelected = createSelector(getEntities, getSelectedId, (entities, selectedId) => {
    return entities[selectedId];
});
export const needReviews = (state: State) => state.need;
export const needSelectedReviews = createSelector(needReviews, getSelectedId, (reviews, selectedId) => {
    if (reviews[selectedId] !== undefined) {
        return reviews[selectedId];
    }
    return false;
});
