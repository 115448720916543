import { ElementInterface, Message } from '@app/core/interfaces';
import { Action } from '@ngrx/store';
import { type } from '../util';

/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
export const ADD = type('[element] add');
/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
export const ADD_SUCCESS = type('[element] add success');
/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
export const ADD_ERROR = type('[element] add error');
/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
export const SELECT = type('[element] select');

/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
export class AddElement implements Action {
    readonly type = ADD;
    constructor(public payload: string) { }
}

/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
export class AddElementSuccess implements Action {
    readonly type = ADD_SUCCESS;
    constructor(public payload: ElementInterface) { }
}

/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
export class AddElementError implements Action {
    readonly type = ADD_ERROR;
    constructor(public payload: { errors: Message[] }) { }
}

/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
export type Actions
    = AddElement
    | AddElementSuccess
    | AddElementError;
