import * as elements from '../actions/elements.actions';
import { createSelector } from '@ngrx/store';
import { ElementInterface, Message } from '@app/core/interfaces';

export interface State {
    entities: { [id: string]: ElementInterface };
    ids: string[];
    selectedId: number;
    loaded: boolean;
    loading: boolean;
    errors: Message[];
}

const initialState: State = {
    entities: {},
    ids: [],
    selectedId: null,
    loaded: false,
    loading: false,
    errors: []
};

export function reducer(state: any = initialState, action: elements.Actions): State {
    switch (action.type) {

        case elements.ADD: {
            return Object.assign({}, state, {
                loading: true,
                loaded: false
            });
        }

        case elements.ADD_SUCCESS: {
            const id = action.payload.element_naam;

            if (state.ids.includes(id)) {
                return Object.assign({}, state, {
                    selectedId: id,
                    loading: false,
                    loaded: true
                });
            }

            return Object.assign({}, state, {
                entities: Object.assign({}, state.entities, {
                    [id]: action.payload
                }),
                ids: [...state.ids, id],
                selectedId: id,
                loading: false,
                loaded: true
            });
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = createSelector(getEntities, getSelectedId, (entities, selectedId) => entities[selectedId]);
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map(id => entities[id]));
