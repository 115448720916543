import { Action } from '@ngrx/store';
import { type } from '@app/store/util';
import { Review, Message } from '@app/core/interfaces';

export const LOAD_REVIEWS = type('[Reviews] Load Reviews');
export const LOAD_REVIEWS_SUCCESS = type('[Reviews] Load Reviews Success');
export const SET_SELECTED = type('[Reviews] Set Selected Product Name');
export const SET_SELECTED_REVIEW_PAGE_NUMBER = type('[Reviews] Set Selected Review Page Number');
export const SET_TOTAL_REVIEW_PAGES = type('[Reviews] Set Total Review Pages');
export const SET_ERROR = type('[Reviews] Set Error');

export class LoadReviews implements Action {
    readonly type = LOAD_REVIEWS;
    constructor(public payload: { product_id: string, limit?: number }) {}
}

export class LoadReviewsSuccess implements Action {
    readonly type = LOAD_REVIEWS_SUCCESS;
    constructor(public payload: { reviews: Review[], totalReviewPages: number }) {}
}

export class SetSelectedProductName implements Action {
    readonly type = SET_SELECTED;
    constructor(public payload: { product_id: string }) {}
}

export class SetSelectedReviewPageNumber implements Action {
    readonly type = SET_SELECTED_REVIEW_PAGE_NUMBER;
    constructor(public payload: { pageNumber: number }) {}
}

export class SetTotalReviewPages implements Action {
    readonly type = SET_TOTAL_REVIEW_PAGES;
    constructor(public payload: { totalReviewPages: number }) {}
}

export class SetError implements Action {
    readonly type = SET_ERROR;
    constructor(public payload: { errors: Message[] }) {}
}

export type Actions
    = LoadReviews
    | LoadReviewsSuccess
    | SetSelectedProductName
    | SetSelectedReviewPageNumber
    | SetTotalReviewPages
    | SetError;
