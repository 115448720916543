import { Action } from '@ngrx/store';
import { type } from '@app/store/util';
import { Message } from '@app/core/interfaces';
import { NotificationInterface } from 'outshared-lib';


export const ERROR_OCCURRED = type('[error] Default');
export const ERROR_RESET = type('[error] Reset');

export class ErrorOccurred implements Action {
    readonly type = ERROR_OCCURRED;
    constructor( public payload: { action?: Action, error?: NotificationInterface[] }) {}
}

export class ErrorReset implements Action {
    readonly type = ERROR_RESET;
    constructor() {}
}

export type ActionTypes
    = ErrorOccurred
    | ErrorReset;
