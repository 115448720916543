import * as layoutActions from '@app/store/actions/layout.actions';

export interface BreadCrumbItem {
    title: string;
    routerLink?: string | boolean;
    href?: string | boolean;
}

export interface State {
    layout: {
        breadcrumb: BreadCrumbItem[],
        activePage: string,
        mobileMenu: boolean
    };
}

export const initialState: State = {
    layout: {
        breadcrumb: [],
        activePage: '',
        mobileMenu: true,
    },
};

export function reducer(state: State = initialState, action: layoutActions.ActionTypes): State {
    switch (action.type) {
        case layoutActions.SET_BREADCRUMB: {
            const breadcrumb: BreadCrumbItem[] = [];
            if (action.payload) {
                action.payload.forEach((item: BreadCrumbItem) => {
                    const newItem = { ...item };
                    if (!item.hasOwnProperty('href')) {
                        newItem.href = false;
                    }

                    if (!item.hasOwnProperty('routerLink')) {
                        newItem.routerLink = false;
                    }

                    breadcrumb.push(newItem);
                });
            }


            return Object.assign({}, state, {
                layout: {
                    ...state.layout,
                    breadcrumb: breadcrumb,
                },
            });
        }

        case layoutActions.HIDE_MOBILE_MENU: {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    mobileMenu: false,
                },
            };
        }

        case layoutActions.SHOW_MOBILE_MENU: {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    mobileMenu: true,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getBreadcrumb = (state: State) => state.layout.breadcrumb;
export const getActivePage = (state: State) => state.layout.activePage;
export const getMobileMenu = (state: State) => state.layout.mobileMenu;
