import { Action } from '@ngrx/store';
import { NotificationInterface } from 'outshared-lib';
import * as errorsAction from '../actions/errors.actions';

// TODO: Deze store uitfaseren - ticket maken
export interface State {
    lastError: {
        action?: Action,
        error?: NotificationInterface[]
    };
}

export const initialState: State = {
    lastError: {
        action: null,
        error: [],
    },
};

export function reducer(state: State = initialState, action: errorsAction.ActionTypes): State {
    switch (action.type) {
        case errorsAction.ERROR_OCCURRED: {
            return {
                ...state,
                lastError: action.payload,
            };
        }

        case errorsAction.ERROR_RESET: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

export const getLastError = (state: State) => state.lastError;
