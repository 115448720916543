import { createSelector } from '@ngrx/store';
import * as fromProductGrades from './product-grade/product-grade.reducer';
import * as fromElements from './reducers/elements.reducer';
import * as fromErrors from './reducers/errors.reducer';
import * as fromFavoriteReviews from './reducers/favorite_reviews.reducer';
import * as fromLayout from './reducers/layout.reducer';
import * as fromReviews from './reducers/reviews.reducer';

export interface State {
    errors: fromErrors.State;
    layout: fromLayout.State;
    elements: fromElements.State;
    productGrade: fromProductGrades.State;
    reviews: fromReviews.State;
    favoriteReviews: fromReviews.State;
}

export const reducers = {
    errors: fromErrors.reducer,
    layout: fromLayout.reducer,
    elements: fromElements.reducer,
    productGrade: fromProductGrades.reducer,
    reviews: fromReviews.reducer,
    favoriteReviews: fromFavoriteReviews.reducer,
};

/**
 * Errors reducers
 */
export const getErrorState = (state: State) => state.errors;
export const getLastError = createSelector(getErrorState, fromErrors.getLastError);

export const getError = (actionType: string) =>
    createSelector(getLastError, (lastError) => {
        return lastError.action.type === actionType ? lastError.error : [];
    });

/**
 * Elements reducers
 */
export const getElementsState = (state: State) => state.elements;
export const getElementEntities = createSelector(getElementsState, fromElements.getEntities);
export const getElementIds = createSelector(getElementsState, fromElements.getIds);
export const getAllElements = createSelector(getElementsState, fromElements.getAll);
export const getSelectedElementId = createSelector(getElementsState, fromElements.getSelectedId);
export const getSelectedElement = createSelector(getElementsState, fromElements.getSelected);
export const selectElement = (id: string) => createSelector(getElementEntities, (entities) => entities[id]);

/**
 * Layout store
 */
export const getLayoutState = (state: State) => state.layout;
export const getLayoutBreadcrumb = createSelector(getLayoutState, fromLayout.getBreadcrumb);
export const getLayoutPage = createSelector(getLayoutState, fromLayout.getActivePage);
export const getMobileMenu = createSelector(getLayoutState, fromLayout.getMobileMenu);

/**
 *  Review selectors
 */
export const getReviewsState = (state: State) => state.reviews;
export const getReviewsEntities = createSelector(getReviewsState, fromReviews.getEntities);
export const getSelectedReviews = createSelector(getReviewsState, fromReviews.getSelected);
export const getReviewsErrors = createSelector(getReviewsState, fromReviews.getErrors);
export const needReviews = createSelector(getReviewsState, fromReviews.needReviews);
export const needSelectedReviews = createSelector(getReviewsState, fromReviews.needSelectedReviews);
export const getSelectedProductName = createSelector(getReviewsState, fromReviews.getSelectedId);
export const getSelectedPageNumber = createSelector(getReviewsState, fromReviews.getSelectedPageNumber);
export const getTotalReviewPages = createSelector(getReviewsState, fromReviews.getTotalReviewPages);

/**
 *  Favorite Review selectors
 */
export const getFavoriteReviewsState = (state: State) => state.favoriteReviews;
export const getFavoriteReviewsEntities = createSelector(getFavoriteReviewsState, fromFavoriteReviews.getEntities);
export const getSelectedFavoriteReviews = createSelector(getFavoriteReviewsState, fromFavoriteReviews.getSelected);
export const getFavoriteReviewsErrors = createSelector(getFavoriteReviewsState, fromFavoriteReviews.getErrors);
export const needFavoriteReviews = createSelector(getFavoriteReviewsState, fromFavoriteReviews.needReviews);
export const needSelectedFavoriteReviews = createSelector(
    getFavoriteReviewsState,
    fromFavoriteReviews.needSelectedReviews
);
export const getSelectedFavoriteProductName = createSelector(
    getFavoriteReviewsState,
    fromFavoriteReviews.getSelectedId
);
export const getSelectedFavoritePageNumber = createSelector(
    getFavoriteReviewsState,
    fromFavoriteReviews.getSelectedPageNumber
);
export const getTotalFavoriteReviewPages = createSelector(
    getFavoriteReviewsState,
    fromFavoriteReviews.getTotalReviewPages
);
