import { Action } from '@ngrx/store';
import { type } from '@app/store/util';
import { Review, Message } from '@app/core/interfaces';

export const LOAD_FAVORITE_REVIEWS = type('[Favorite Reviews] Load Reviews');
export const LOAD_FAVORITE_REVIEWS_SUCCESS = type('[Favorite Reviews] Load Reviews Success');
export const SET_SELECTED_FAVORITE = type('[Favorite Reviews] Set Selected Product Name');
export const NEED_SELECTED_FAVORITE =  type('[Favorite Reviews] Need Selected Product');
export const SET_SELECTED_FAVORITE_REVIEW_PAGE_NUMBER = type('[Favorite Reviews] Set Selected Review Page Number');
export const SET_TOTAL_FAVORITE_REVIEW_PAGES = type('[Favorite Reviews] Set Total Review Pages');
export const SET_ERROR = type('[Favorite Reviews] Set Error');

export class LoadFavoriteReviews implements Action {
    readonly type = LOAD_FAVORITE_REVIEWS;
    constructor(public payload: { product_id: string, limit?: number }) {}
}

export class LoadFavoriteReviewsSuccess implements Action {
    readonly type = LOAD_FAVORITE_REVIEWS_SUCCESS;
    constructor(public payload: { reviews: Review[] }) {}
}

export class SetSelectedFavoriteProductName implements Action {
    readonly type = SET_SELECTED_FAVORITE;
    constructor(public payload: { product_id: string }) {}
}

export class NeedSelectedFavoriteProduct implements Action {
    readonly type = NEED_SELECTED_FAVORITE;
    constructor(public payload: { product_id: string }) {}
}
export class SetSelectedFavoriteReviewPageNumber implements Action {
    readonly type = SET_SELECTED_FAVORITE_REVIEW_PAGE_NUMBER;
    constructor(public payload: { pageNumber: number }) {}
}

export class SetTotalFavoriteReviewPages implements Action {
    readonly type = SET_TOTAL_FAVORITE_REVIEW_PAGES;
    constructor(public payload: { totalReviewPages: number }) {}
}

export class SetError implements Action {
    readonly type = SET_ERROR;
    constructor(public payload: { errors: Message[] }) {}
}

export type Actions
    = LoadFavoriteReviews
    | LoadFavoriteReviewsSuccess
    | SetSelectedFavoriteProductName
    | NeedSelectedFavoriteProduct
    | SetSelectedFavoriteReviewPageNumber
    | SetTotalFavoriteReviewPages
    | SetError;
