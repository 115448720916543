import * as reviewsActions from '@app/store/actions/reviews.actions';
import { Review, Message } from '@app/core/interfaces';
import { createSelector } from '@ngrx/store';

export interface State {
    entities: { [product_name: string]: Review[] };
    selectedPageNumber: number;
    totalReviewPages: number;
    selectedId: string | null;
    errors: Message[];
    need: { [product_name: string]: boolean };
}

const initialState: State = {
    entities: {},
    selectedPageNumber: 0,
    totalReviewPages: 0,
    selectedId: null,
    errors: [],
    need: {}
};

export function reducer(state: State = initialState, action: reviewsActions.Actions): State {
    switch (action.type) {

        case reviewsActions.LOAD_REVIEWS: {
            const product_name = state.selectedId;
            return {
                ...state,
                need: Object.assign({}, state.need, {
                    [product_name]: false
                }),
            };
        }

        case reviewsActions.LOAD_REVIEWS_SUCCESS: {
            if (!action.payload) {
                return state;
            }
            const product_name = state.selectedId;
            const reviews = action.payload.reviews;

            return {
                ...state,
                entities: Object.assign({}, state.entities, {
                    [product_name]: reviews
                }),
                totalReviewPages: action.payload.totalReviewPages,
                selectedId: product_name,
                need: Object.assign({}, state.need, {
                    [product_name]: false
                }),
            };
        }

        case reviewsActions.SET_SELECTED: {
            const product_name = action.payload.product_id;

            return {
                ...state,
                selectedId: product_name,
            };
        }

        case reviewsActions.SET_ERROR: {
            return {
                ...state,
                errors: action.payload.errors
            };
        }

        case reviewsActions.SET_SELECTED_REVIEW_PAGE_NUMBER: {
            const product_name = state.selectedId;

            return {
                ...state,
                selectedPageNumber: action.payload.pageNumber,
                need: Object.assign({}, state.need, {
                    [product_name]: true
                }),
            };
        }

        case reviewsActions.SET_TOTAL_REVIEW_PAGES: {
            return {
                ...state,
                totalReviewPages: action.payload.totalReviewPages
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State) => state.entities;
export const getErrors = (state: State) => state.errors;
export const getSelectedPageNumber = (state: State) => state.selectedPageNumber;
export const getSelectedId = (state: State) => state.selectedId;
export const getTotalReviewPages = (state: State) => state.totalReviewPages;
export const getSelected = createSelector(getEntities, getSelectedId, (entities, selectedId) => {
    return entities[selectedId];
});
export const needReviews = (state: State) => state.need;
export const needSelectedReviews = createSelector(needReviews, getSelectedId, (reviews, selectedId) => {
    if (reviews.hasOwnProperty(selectedId)) {
        return reviews[selectedId];
    }
    return false;
});
